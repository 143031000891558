<template>
  <div>
    <link
      rel="stylesheet"
      href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
    />
    <div class="tw-mx-auto">
      <div class="sticky-menu">
        <div
          class="tw-flex tw-justify-between tw-bg-white tw-p-2.5 tw-relative tw-rounded-t-lg"
          style="border-bottom: 1px solid #e6e6e6"
        >
          <h1
            class="tw-relative tw-top-0 tw-inline-flex tw-items-center tw-font-black tw-leading-8 tw-gap-x-2"
            style="padding: 0 38px"
          >
            <button @click="jumpDay('subtract')" class="jump-back">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="tw-w-6 tw-h-6"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <button @click="jumpDay('add')" class="jump-forward">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="tw-w-6 tw-h-6"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <span class="today">{{ formatDate(gridDate) }}</span>
            <div class="tw-relative tw-z-50 tw-inline-flex tw-cursor-pointer tw-justify-self-start">
              <el-date-picker
                v-model="grid.date.day"
                :placeholder="grid.date.day.format('DD/MM/YYYY')"
                type="date"
                value-format="timestamp"
                align="right"
                prefix-icon="tw-hidden"
                class="tw-absolute tw-opacity-0 admin-input__date-picker header"
                :clearable="false"
              >
              </el-date-picker>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="tw-w-8 tw-h-8 date-chevron"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
          </h1>
          <div
            class="tw-rounded-lg tw-cursor-pointer save-badge tw-gap-x-1"
            @click="() => onGridChanges('false')"
          >
            <span>Grid Changes {{ matchesUpdating ? "Saving" : "Saved" }}</span>
            <svg
              v-if="!matchesUpdating"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="tw-w-4 tw-h-4"
              style="color: #26463a"
            >
              <path
                fill-rule="evenodd"
                d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="tw-w-4 tw-h-4 loading"
              style="color: #26463a"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
              />
            </svg>
          </div>
        </div>
        <grid-settings-tabs
          :colors="colors"
          :competitions="competitions"
          :grid="grid"
          :gridRefresh="gridRefresh"
          :search="search"
          :venues="venues"
          @updatePanelHeight="updatePanelHeight"
          @orderGroup="orderGroup"
          @getMatches="getMatches"
          @populateGrid="populateGrid"
        />
      </div>
      <match-grid-inputs
        :grid="grid"
        :timesBetween="timesBetween"
        :timeslotIncrements="timeslotIncrements"
        @jumpDay="jumpDay"
        @updateIncrement="updateIncrement"
        @updateTimeRange="updateTimeRange"
      />
      <edit-match-modal
        :allTeams="allTeams"
        :editMatch="editMatch"
        :overrideScores="overrideScores"
        :showDeleteModal="showDeleteModal"
        :showMatchModal="showMatchModal"
        :venues="venues"
        @updateMatch="updateMatch"
        @cancelForm="cancelForm"
        @cancelDelete="cancelDelete"
        @deleteMatch="deleteMatch"
        @deleteMatchModal="deleteMatchModal"
        @hideMatchModal="hideMatchModal"
      />
      <div
        :class="`tw-relative tw-overscroll-contain grid-calendar venue-col-${gridSize}`"
        style="background-color: #e6e6e6; border: 1px solid #e6e6e6; border-radius: 2px"
      >
        <div class="tw-sticky tw-flex tw-justify-end headers tw-top-11">
          <div v-show="grid.groupsLvl1.length > 0" class="buttons">
            <button class="tw-rounded-lg btn-left">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="tw-w-5 tw-h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                style="color: rgb(38, 70, 58)"
              >
                <path
                  fill-rule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
            <button class="tw-rounded-lg btn-right">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="tw-w-5 tw-h-5"
                viewBox="0 0 20 20"
                fill="currentColor"
                style="color: rgb(38, 70, 58)"
              >
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </div>
          <venues v-if="!gridRefresh" :grid="grid" :timezone="timezone" />
        </div>
        <!-- main grid section -->
        <fixture-grid
          v-if="!gridRefresh"
          :grid="grid"
          :gridRefresh="gridRefresh"
          :colors="colors"
          :timesBetween="timesBetween"
          :timeslotIncrements="timeslotIncrements"
          :timezone="timezone"
        />
      </div>
    </div>
    <div id="top-of-site-pixel-anchor"></div>
  </div>
</template>
<style>
.match-grid-bg {
  background-color: #e6e6e6;
}
button.el-button {
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
}

.today {
  color: #222222;
  font-size: 20px;
  font-weight: 900;
  line-height: 32px;
}

body {
  overflow-x: hidden;
}
.sticky-menu {
  position: sticky;
  top: 0;
  z-index: 60;
}
.sticky-header {
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: transparent;
  z-index: 20;
  position: sticky;
  top: 0;
  font-weight: 700;
  overflow: hidden;
}

.sticky-header span {
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #222222;
  opacity: 0;
  transform: translateY(-100%);
  transition: 0.4s;
}

.reveal .sticky-header span {
  opacity: 1;
  transform: none;
}

.headers {
  position: -webkit-sticky;
  z-index: 21;
}
.headers.tw-sticky {
  transition: 0.1s;
}

.details {
  border-radius: 4px;
  background: #fff;
  padding: 12px 8px;
  cursor: pointer;
  border-radius: 4px;
}

h3 {
  font-size: 12px;
  background: var(--theme-bg);
  padding: 2px 5px;
  font-weight: 600;
  line-height: 1.4;
  border-radius: 2px;
  color: var(--theme-color);
  width: auto;
  display: inline;
}

p {
  font-size: 12px;
  color: #333;
  font-weight: 500;
  margin: 0;
}

p + p {
  color: #858585;
  margin-top: 5px;
  font-weight: 400;
}

.buttons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 6;
  height: 100%;
  padding: 1px 0;
  width: calc(100% - 75px);
}

button {
  border-radius: 0;
  border: 0;
  padding: 5px;
  font: inherit;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none !important;
  cursor: pointer;
  background: #f7f9fb;
}

.btn-left,
.btn-right {
  opacity: 0;
  transition: all 0.5s linear;
  background-color: rgba(218, 236, 229, 0.5);
}
.btn-left:hover,
.btn-right:hover {
  opacity: 1;
}

.tba {
  text-align: right;
}

#top-of-site-pixel-anchor {
  position: absolute;
  width: 1px;
  height: 1px;
  top: 15em;
  left: 0;
}

.date-chevron {
  transition: all linear 0.3s;
}
.rotate {
  transform: rotate(180deg);
}

.clash span {
  display: inline-flex;
}

.custom-enter-active,
.custom-leave-active {
  transition: all 0.15s;
}

[class^="jump-"] {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  z-index: 50;
  padding: 0;
  background: transparent;
  border-radius: 6px;
  width: 30px;
  margin: 2px;
}
[class^="jump-"] svg {
  color: #7f7f7f;
}
.jump-back {
  left: 0;
}
.jump-forward {
  right: 0;
}
[class^="jump-"]:hover {
  background-color: #daece5;
  transition: background-color 0.3s cubic-bezier(0.46, 0.03, 0.52, 0.96);
}
[class^="jump-"]:hover svg {
  color: #098255;
  transition: color 0.3s cubic-bezier(0.46, 0.03, 0.52, 0.96);
}

.save-badge {
  background-color: #daece5;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  text-align: right;
  flex: none;
  order: 0;
  flex-grow: 0;
}
.save-badge span {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.clash-alert {
  margin-bottom: 2px;
  margin-left: auto;
  -ms-flex-item-align: end;
}
.has-clash .clash-alert:only-child {
  display: none;
}
.clash-alert div {
  color: #fff;
  width: fit-content;
  border-radius: 4px;
  background-color: #d0011b;
  padding: 6px 10px;
  display: flex;
  margin-right: 0;
  margin-left: auto;
}
.clash-alert span {
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
}

.accordion.active .match-title span {
  font-weight: 700 !important;
  transition: font-weight 0.3s ease-out;
}

.active + .panel {
  opacity: 1;
  overflow: visible;
}
.panel {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  row-gap: 8px;
  align-items: flex-start;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-out, overflow 0.3s ease-in, opacity 0.3s ease-in;
}
.panel p {
  font-weight: 400;
  font-size: 9px;
  line-height: 14px;
  color: #4d4d4d;
  align-self: center;
}

.rotate-icon {
  animation: rotate 0.3s;
  animation-fill-mode: forwards;
  -webkit-animation: rotate 0.3s;
  -webkit-animation-fill-mode: forwards;
}
.icon-default {
  animation: rotate-back 0.3s;
  animation-fill-mode: forwards;
  -webkit-animation: rotate-back 0.3s;
  -webkit-animation-fill-mode: forwards;
}

.loading {
  animation: loading-rotate 1.5s infinite;
  animation-fill-mode: forwards;
  -webkit-animation: loading-rotate 1.5s infinite;
  -webkit-animation-fill-mode: forwards;
}

.venue-col-med {
  width: fit-content;
}
.venue-col-sm .details {
  max-width: 684px;
}
.venue-col-sm [data-venue-id],
.venue-col-sm .venue-container .track:not(.time) {
  max-width: max-content;
}
.venue-col-med .details {
  max-width: 484px;
}
.venue-col-med [data-venue-id],
.venue-col-med .venue-container .track:not(.time) {
  max-width: max-content;
}
.venue-col-lge .details {
  max-width: 250px;
}
.venue-col-lge [data-venue-id],
.venue-col-lge .venue-container .track:not(.time) {
  max-width: max-content;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
@-webkit-keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(180deg);
  }
}
@keyframes rotate-back {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes rotate-back {
  from {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(0deg);
  }
}
@keyframes loading-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loading-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 979px) {
  .grid-calendar {
    width: initial !important;
  }
  .tracks {
    overflow: auto !important;
  }
}
@media (max-width: 767px) {
  .track:not(.time) {
    flex: 1 0 calc(50% + 7px);
  }
}
</style>
<script>
import _ from "lodash";
import { has } from "lodash";
import ScrollSync from "vue-scroll-sync";
import { matchStatus } from "@/utils/constants";
import matchStore from "../../stores/match";
import moment from "moment";
import EditMatchModal from "./EditMatchModal.vue";
import FixtureGrid from "./FixtureGrid.vue";
import GridSettingsTabs from "./GridSettingsTabs.vue";
import MatchGridInputs from "./MatchGridInputs.vue";
import Venues from "./Venues.vue";

export default {
  components: {
    ScrollSync,
    Venues,
    EditMatchModal,
    FixtureGrid,
    GridSettingsTabs,
    MatchGridInputs
  },
  data() {
    const {
      search: { venueIds, fieldNo, competitionIds, ageLvls, isClashOnly, showByes },
      venues
    } = this.$store.state.matchGrid;
    return {
      allTeams: [],
      colors: [
        ["yellow", "rgb(245, 158, 11)"],
        ["blue", "rgb(59, 130, 246)"],
        ["indigo", "rgb(99, 102, 241)"],
        ["pink", "rgb(236, 72, 153)"],
        ["green", "rgb(16, 185, 129)"],
        ["purple", "rgb(139, 92, 246)"],
        ["gray", "rgb(107, 114, 128)"],
        ["slate", "rgb(100, 116, 139)"],
        ["red", "rgb(239, 68, 68)"],
        ["orange", "rgb(249, 115, 22)"],
        ["lime", "rgb(132, 204, 22)"],
        ["teal", "rgb(20, 184, 166)"],
        ["cyan", "rgb(6, 182, 212)"],
        ["rose", "rgb(244, 63, 94)"],
        ["fuchsia", "rgb(217, 70, 239)"]
      ],
      competitions: [],
      editMatch: {
        competition: {
          _id: null,
          name: null
        },
        orgtree: {
          association: { _id: null, name: null }
        },
        round: {
          type: null,
          number: null,
          displayName: ""
        },
        venue: {
          _id: null,
          name: null
        },
        homeTeam: {
          _id: null,
          name: null
        },
        awayTeam: {
          _id: null,
          name: null
        },
        meta: {
          isTba: false,
          isBye: false,
          fieldNo: ""
        },
        status: null,
        dateTime: null
      },
      grid: {
        competitions: [],
        items: [],
        groupsLvl1: [],
        date: {
          day: null,
          start: null,
          end: null
        }
      },
      gridChanges: false,
      gridRefresh: false,
      has,
      matches: [],
      matchesCompList: [],
      matchesUpdating: false,
      overrideScores: false,
      recentPanelHeight: undefined,
      search: {
        dateRange: [],
        venueIds,
        fieldNo,
        competitionIds,
        ageLvls,
        isClashOnly,
        showByes
      },
      setDay: null,
      showDeleteModal: false,
      showMatchModal: false,
      showModal: false,
      statusTypes: matchStatus,
      timesBetween: {
        start: 8,
        finish: 22
      },
      timeslotIncrements: 60, // value in minutes
      timezone: "Australia/Sydney",
      trackMatches: {},
      venues
    };
  },
  beforeCreate() {
    this.$store.registerModule("match", matchStore);
  },
  beforeDestroy() {
    this.$store.commit("match/resetState");
    this.$store.unregisterModule("match");
  },
  beforeRouteLeave(to, from, next) {
    if (this.gridChanges) {
      this.$confirm("You have unsaved changes that will be lost", "Unsaved Changes")
        .then(() => next())
        .catch(() => next(false));
    } else {
      next();
    }
  },
  created() {
    if (this.grid.date) {
      this.grid.date.day = this.moment();
      this.grid.date.start = this.moment()
        .startOf("Day")
        .valueOf();
      this.grid.date.end = this.moment()
        .endOf("Day")
        .valueOf();
    }
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.timezone = tz;
  },
  async mounted() {
    try {
      this.$store.commit("root/loading", true);
      const comps = await this.$http.get("/nrl/api/v1/admin/competitions");
      if (!comps.data) throw new Error("Could not load competition data");
      if (Array.isArray(comps.data.data)) this.competitions = comps.data.data;

      // let observer = new IntersectionObserver((entries) => {
      //   if (!entries[0].isIntersecting) {
      //     document.body.classList.add("reveal");
      //   } else {
      //     document.body.classList.remove("reveal");
      //   }
      // });
      // observer.observe(document.querySelector("#top-of-site-pixel-anchor"));

      window.addEventListener("scroll", e => {
        const calendarExists = document.querySelector(".el-picker-panel.el-date-picker.el-popper");
        if (!calendarExists || calendarExists.style.display === "none") {
          const dateSvg = document.querySelector(".date-chevron");
          if (dateSvg && dateSvg.classList) dateSvg.classList.remove("rotate");
        } else {
          const dateSvg = document.querySelector(".date-chevron");
          if (dateSvg && dateSvg.classList) dateSvg.classList.add("rotate");
        }
      });
      window.addEventListener("click", e => {
        setTimeout(() => {
          const calendarExists = document.querySelector(
            ".el-picker-panel.el-date-picker.el-popper"
          );
          if (!calendarExists || calendarExists.style.display === "none") {
            const dateSvg = document.querySelector(".date-chevron");
            if (dateSvg && dateSvg.classList) dateSvg.classList.remove("rotate");
          } else {
            const dateSvg = document.querySelector(".date-chevron");
            if (dateSvg && dateSvg.classList) dateSvg.classList.add("rotate");
          }
        }, 350);
      });

      // Initialises sitcky menu spacing on scroll
      const stickyMenu = document.querySelector(".sticky-menu");
      const initTop = stickyMenu.scrollHeight + 2;
      const venueTop = document.querySelector(".headers.tw-sticky");
      venueTop.style.top = `${initTop}px`;

      // initialises tab header bottom border
      document.querySelector(".el-tabs__header").style.borderBottomColor = "1px solid transparent";

      const tabs = document.querySelectorAll(".el-tabs__item");
      const vm = this;
      tabs.forEach(tab => {
        tab.addEventListener("click", function() {
          // checks if accordian is open or closed and changes text accordingly
          const headerTabs = document.querySelectorAll(".el-tabs__item");
          for (const t of headerTabs) {
            if (
              t.getAttribute("aria-expanded") == "true" &&
              !t.getAttribute("aria-selected") &&
              tab.id !== t.id
            ) {
              t.querySelector("svg").classList.remove("rotate-icon");
              t.querySelector("svg").classList.add("icon-default");
              t.setAttribute("aria-expanded", "false");
            }
          }

          if (!this.getAttribute("aria-expanded")) this.setAttribute("aria-expanded", "false");
          let expanded = this.getAttribute("aria-expanded");
          if (expanded == "true") {
            this.querySelector("svg").classList.toggle("rotate-icon");
            this.querySelector("svg").classList.toggle("icon-default");
            this.setAttribute("aria-expanded", "false");
          } else {
            this.querySelector("svg").classList.toggle("rotate-icon");
            this.querySelector("svg").classList.toggle("icon-default");
            this.setAttribute("aria-expanded", "true");
          }
          var panel = document.querySelector(".el-tabs__content");
          const currentTab = this.getAttribute("aria-controls");
          const selectedPanel = panel.querySelector(`#${currentTab}`);

          const activeTabs = document.querySelectorAll(".tab-active");
          for (const t of activeTabs) {
            if (selectedPanel.id !== t.id) t.classList.remove("tab-active");
          }
          selectedPanel.classList.toggle("tab-active");
          if (panel.style.maxHeight && !selectedPanel.classList.contains("tab-active")) {
            panel.style.maxHeight = null;
          } else {
            if (currentTab === "pane-2") {
              panel.style.maxHeight = vm.recentPanelHeight
                ? vm.recentPanelHeight + "px"
                : panel.scrollHeight + "px";
            } else panel.style.maxHeight = panel.scrollHeight + "px";
          }
          const venueNav = document.querySelector(".headers.tw-sticky");
          const stickyMenu = document.querySelector(".sticky-menu");
          setTimeout(() => {
            venueNav.style.top = `${stickyMenu.scrollHeight + 2}px`;
          }, 300);
          const lineCheck = document.querySelector(
            ".sticky-menu [aria-selected='true'][aria-expanded='false']"
          );
          if (lineCheck) {
            document.querySelector(".el-tabs__header").style.borderBottomColor = "transparent";
          } else {
            document.querySelector(".el-tabs__header").style.borderBottomColor = "#EDEDED";
          }
        });
      });
      const mainSection = document.querySelector(".content");
      if (mainSection.classList) mainSection.classList.add("match-grid-bg");
      this.$store.commit("root/loading", false);
    } catch (error) {
      this.$customError(error);
      this.$router.push("/");
      this.$store.commit("root/loading", false);
    }
  },
  beforeDestroy() {
    const mainSection = document.querySelector(".content");
    if (mainSection.classList) mainSection.classList.remove("match-grid-bg");
  },
  methods: {
    cancelForm() {
      this.$store.commit("match/resetState");
      this.showDeleteModal = false;
      this.showMatchModal = false;
      this.overrideScores = false;
    },
    cancelDelete() {
      this.showMatchModal = !this.showMatchModal;
      this.showDeleteModal = !this.showDeleteModal;
    },
    deleteMatchModal() {
      const modal = document.querySelector(".modal");
      modal.style.visibility = "hidden";
      this.showDeleteModal = !this.showDeleteModal;
    },
    equaliseHeight() {
      const times = document.querySelectorAll("[data-time-hour]");
      const firstInstanceOfFilledTsForTime = {};

      const filledTimeslots = [];
      times.forEach(el => {
        const timeslotHasMatches = el.children.length;
        if (timeslotHasMatches) {
          filledTimeslots.push(el);
          if (!firstInstanceOfFilledTsForTime[el.dataset.startTime])
            firstInstanceOfFilledTsForTime[el.dataset.startTime] = el;
        }
      });
      for (const timeslot of filledTimeslots) {
        const timeCoord = timeslot.dataset.startTime;
        const timeArr = timeCoord.split(":");
        const timeDiv = document.querySelector(
          `[data-timeslot='${parseInt(timeArr[0])}'][data-timeslot-increment='${timeArr[1]}']`
        );

        if (timeslot.scrollHeight > timeDiv.scrollHeight + 1) {
          timeDiv.style.height = `${timeslot.scrollHeight}px`;
        }

        const timeslotExpand = document.querySelectorAll(`[data-start-time="${timeCoord}"]`);
        timeslotExpand.forEach(ts => {
          const timeDivCheck = document.querySelector(
            `[data-timeslot='${parseInt(timeArr[0])}'][data-timeslot-increment='${timeArr[1]}']`
          );
          const tCheck = timeDivCheck.style.height;
          if (ts.scrollHeight !== tCheck) ts.style.height = timeDivCheck.style.height;
        });
      }
      for (const time in firstInstanceOfFilledTsForTime) {
        const timeslot = firstInstanceOfFilledTsForTime[time];
        const timeCoord = timeslot.dataset.startTime;
        const allTimeslots = document.querySelectorAll(`[data-start-time='${timeCoord}']`);
        const maxHeights = [];
        allTimeslots.forEach(ts => {
          ts.style.height = "auto";
          maxHeights.push(ts.scrollHeight);
        });
        const largestTimeslot = Math.max(...maxHeights) + 30;
        const timeArr = timeCoord.split(":");
        const timeHeading = document.querySelector(
          `[data-timeslot='${parseInt(timeArr[0])}'][data-timeslot-increment='${timeArr[1]}']`
        );
        if (timeHeading.style.height !== `${largestTimeslot}px`)
          timeHeading.style.height = `${largestTimeslot}px`;
        allTimeslots.forEach(ts => (ts.style.height = `${largestTimeslot}px`));
      }
    },
    setEventlisteners() {
      // adds click and edit functionality for matches
      const gridMatches = document.querySelectorAll("[data-game-id]");
      const vm = this;
      gridMatches.forEach(m => {
        let acc = m.getElementsByClassName("accordion");
        for (const accord of acc) {
          if (!accord.getAttribute("aria-listener")) {
            accord.addEventListener("click", function() {
              const currGameId = this.parentNode.dataset.gameId;

              // Toggle between adding and removing the "active" class,
              // to highlight the button that controls the panel
              this.classList.toggle("active");
              this.parentNode.classList.toggle("admin-shadow__subtle");
              this.parentNode.classList.toggle("admin-shadow__raised");
              this.parentNode.classList.toggle("tw-gap-y-2");

              // checks if accordian is open or closed and changes text accordingly
              if (this.getAttribute("aria-has-icon") == "true") {
                let expanded = this.getAttribute("aria-expanded");
                if (expanded == "true") {
                  this.querySelector("svg:not(.no-edit)").classList.toggle("rotate-icon");
                  this.querySelector("svg:not(.no-edit)").classList.toggle("icon-default");
                  this.setAttribute("aria-expanded", "false");
                } else {
                  this.querySelector("svg:not(.no-edit)").classList.toggle("rotate-icon");
                  this.querySelector("svg:not(.no-edit)").classList.toggle("icon-default");
                  this.setAttribute("aria-expanded", "true");
                }
              }
              const openPanel = this.getAttribute("aria-expanded");
              const accordions = document.querySelectorAll(".details .accordion");
              for (const c of accordions) {
                const gid = c.parentNode.dataset.gameId;
                const parentClassList = c.parentNode.classList;
                const currentOpen = c.getAttribute("aria-expanded") === "true";
                if (openPanel === "true" && currGameId !== gid && currentOpen)
                  c.dispatchEvent(new Event("click"));

                if (
                  parentClassList.contains("read-only") ||
                  (openPanel === "true" && currGameId !== gid)
                ) {
                  c.parentNode.style.opacity = "0.5";
                } else if (openPanel === "false") {
                  const activeCheck = document.querySelectorAll(".accordion.active");
                  c.parentNode.style.opacity =
                    !activeCheck.length || (activeCheck.length && currentOpen) ? "1" : "0.5";
                }
              }

              /* Toggle between hiding and showing the active panel */
              var panel = this.nextElementSibling;
              if (panel.style.maxHeight) {
                panel.style.maxHeight = null;
              } else {
                panel.style.maxHeight = panel.scrollHeight + "px";
              }
            });
            accord.setAttribute("aria-listener", "true");
          }
        }

        let dragged;
        let cardId;
        if (!m.classList.contains("read-only") && !m.getAttribute("aria-listener")) {
          m.addEventListener(
            "dragstart",
            e => {
              dragged = e.target;
              // make it half transparent
              e.target.style.opacity = 0.5;
              e.dataTransfer.setData("text/plain", null);

              const { gameId } = dragged.dataset;
              cardId = gameId;
              const allCards = document.querySelectorAll(".details");
              allCards.forEach(card => {
                if (cardId === card.dataset.gameId) return;
                // card.style.visibility = "hidden";
              });
            },
            false
          );
          m.addEventListener(
            "dragend",
            e => {
              // reset the transparency
              e.target.style.opacity = "";
              const allCards = document.querySelectorAll(".details");
              allCards.forEach(card => {
                if (cardId === card.dataset.gameId) return;
                // card.style.visibility = "initial";
              });
              dragged = null;
              cardId = null;
            },
            false
          );
          document.addEventListener(
            "dragover",
            e => {
              // prevent default to allow drop
              e.preventDefault();
              const tracks = document.querySelector(".tracks");
              if (e.target.classList.contains("scrollRight")) {
                tracks.scrollBy({
                  left: 0.25,
                  behavior: "smooth"
                });
              }
              if (e.target.classList.contains("scrollLeft")) {
                tracks.scrollBy({
                  left: -0.25,
                  behavior: "smooth"
                });
              }
            },
            false
          );
          document.addEventListener(
            "dragenter",
            function(event) {
              // highlight potential drop target when the draggable element enters it
              if (event.target.classList.contains("dropzone")) {
                event.target.style.background = !event.target.children.length
                  ? "#daece5"
                  : "rgba(208, 1, 27, 0.15)";
                event.target.style.border = !event.target.children.length
                  ? "dashed 2px #26463a"
                  : "dashed 2px #D0011B";
              }
            },
            false
          );

          document.addEventListener(
            "dragleave",
            function(event) {
              // reset background of potential drop target when the draggable element leaves it
              if (event.target.classList.contains("dropzone")) {
                event.target.style.background = "";
                event.target.style.border = "";
              }
            },
            false
          );
          document.addEventListener(
            "drop",
            function(event) {
              // prevent default action (open as link for some elements)
              event.preventDefault();
              // move dragged elem to the selected drop target
              if (event.target.classList.contains("dropzone") && dragged) {
                event.target.style.background = "";
                if (dragged) {
                  const parent = dragged.parentNode;
                  parent.removeChild(dragged);
                  event.target.appendChild(dragged);

                  const { gameId } = dragged.dataset;
                  const { venue } = event.target.parentNode.dataset;
                  const { venueTimezone } = event.target.parentNode.parentNode.dataset;
                  const venFieldArr = venue.split("-");
                  const venueId = parseInt(venFieldArr[0]);
                  const field = !venFieldArr[1] ? "undefined" : venFieldArr[1];
                  const { timeHour, startTime } = event.target.dataset;
                  const timeArr = startTime.split(":");
                  const setVenue = venueTimezone ? venueTimezone : vm.timezone;
                  const newTime = vm.setDay
                    .hour(parseInt(timeArr[0]))
                    .startOf("hour")
                    .add(parseInt(timeArr[1]), "minutes")
                    .valueOf();
                  const localOffset = vm.setDay
                    .hour(parseInt(timeArr[0]))
                    .startOf("hour")
                    .add(parseInt(timeArr[1]), "minutes")
                    .utcOffset();
                  const venueOffset = vm.setDay
                    .hour(parseInt(timeArr[0]))
                    .startOf("hour")
                    .add(parseInt(timeArr[1]), "minutes")
                    .tz(setVenue)
                    .utcOffset();
                  const diffInMs = (localOffset - venueOffset) * 60 * 1000;
                  // TODO: tech debt when saving match saves to venue local time not computer local time.
                  const alteredTime = newTime + diffInMs;
                  vm.trackMatches[gameId] = _.merge(vm.trackMatches[gameId], {
                    changes: {
                      venueId,
                      field,
                      timeslot: newTime
                    }
                  });

                  const timeContainer = dragged.querySelector("span.round-time");
                  timeContainer.innerHTML = `${startTime}${timeHour < 12 ? "am" : "pm"}`;
                  if (!vm.gridChanges) vm.gridChanges = true;
                  if (vm.gridChanges) vm.onGridChanges();
                }
              }
            },
            false
          );
          const { gameId } = m.dataset;
          const matchModalButton = m.querySelector(".admin-button__tiny");
          matchModalButton.addEventListener("click", e => {
            this.$store
              .dispatch("match/getMatch", parseInt(gameId))
              .then(() => {
                this.editMatch = this.$store.getters["match/match"];
                const compid = this.editMatch.competition._id;
                this.$http
                  .get(`/nrl/api/v1/admin/teams/competition/${compid}`)
                  .then(teamsRes => {
                    this.allTeams = teamsRes.data.data;
                    this.showMatchModal = true;
                  })
                  .catch(err => {
                    this.$customError(err);
                  });
              })
              .catch(err => {
                this.$customError(err);
              });
          });
        } else if (m.classList.contains("read-only") && !m.getAttribute("aria-listener")) {
          const matchModalButton = m.querySelector(".admin-button__tiny");
          matchModalButton.style.opacity = "0.5";
          matchModalButton.style.cursor = "not-allowed";
          matchModalButton.addEventListener("click", e => {
            this.$customError("Cannot edit a match once finalised");
          });
        }
        m.setAttribute("aria-listener", "true");
      });
    },
    async deleteMatch() {
      try {
        const { _id } = this.editMatch;
        if (!_id) return;
        this.$store.commit("root/loading", true);
        console.log("deleting match " + _id);
        await this.$http.delete(`nrl/api/v1/admin/matches/${_id}`);
        this.cancelForm();
        const { start, end } = this.grid.date;
        this.getMatches([start, end]);
        this.$store.commit("root/loading", false);
      } catch (error) {
        this.$store.commit("root/loading", false);
        this.$customError(get(error, "response.data.message"));
      }
    },
    drag(ev) {
      ev.dataTransfer.setData("text", ev.target.id);
    },
    formatDate(date) {
      return moment(date).format("dddd MMMM Do YYYY");
    },
    formatGrid(comps, venues, matchCardsContent) {
      this.grid.competitions = comps;
      this.grid.groupsLvl1 = venues;

      this.orderGroup();
      console.log("Formatting Grid", this.matches, { matchCardsContent });
      this.grid.items = matchCardsContent;
    },
    async getMatches(date, rerender = true) {
      try {
        this.$store.commit("root/loading", true);
        if (rerender) this.gridRefresh = !this.gridRefresh;
        this.search.dateRange = date;
        const gridData = await this.$http.post(
          `nrl/api/v1/admin/matches/reworked-grid/search?timezone=${this.timezone}`,
          this.search
        );
        if (!gridData.data) throw new Error("Could not retrieve matches");
        if (Array.isArray(gridData.data.data.matches)) this.matches = gridData.data.data.matches;
        if (
          !gridData.data.data &&
          !gridData.data.data.competitions &&
          !Array.isArray(gridData.data.data.competitions)
        )
          this.$customError("Could not retrieve matches' comp list");
        const comps =
          gridData.data.data && gridData.data.data.competitions
            ? gridData.data.data.competitions
            : [];

        if (
          !gridData.data.data &&
          !gridData.data.data.venues &&
          !Array.isArray(gridData.data.data.venues)
        )
          this.$customError("Could not retrieve matches' venue list");

        const venues =
          gridData.data.data && gridData.data.data.venues ? gridData.data.data.venues : [];

        const sorted =
          gridData.data.data &&
          gridData.data.data.sortedMatches &&
          !_.isEmpty(gridData.data.data.sortedMatches)
            ? gridData.data.data.sortedMatches
            : undefined;

        if (comps.length && !sorted) throw new Error("Could not retrieve matches");

        if (rerender) {
          this.formatGrid(comps, venues, sorted);
          this.gridRefresh = !this.gridRefresh;
          setTimeout(() => {
            this.populateGrid();
            this.matchesUpdating = false;
          }, 500);
        }
        console.log("getting matches ", this.matches);
      } catch (error) {
        const eType =
          error && error.response && error.response.status ? error.response.status : undefined;
        const errMsg = eType && eType === 403 ? "403: Unauthorised Role" : error;
        this.$customError(errMsg);
        this.$store.commit("root/loading", false);
        this.matchesUpdating = false;
        if (eType === 403) this.$router.push("/");
      }
    },
    getTeamName(team, match) {
      if (team && team.name) return team.name;
      if (match.meta && match.meta.isBye) return "BYE";
      if (match.meta && match.meta.isTba) return "TBA";
      return "";
    },
    hideMatchModal() {
      this.showMatchModal = false;
    },
    jumpDay(dir) {
      if (dir === "add") {
        this.grid.date.day = this.grid.date.day.add(1, "days");
      }
      if (dir === "subtract") {
        this.grid.date.day = this.grid.date.day.subtract(1, "days");
      }
      const { day } = this.grid.date;
      this.grid.date.day = moment(new Date(day));
    },
    onGridChanges(refresh) {
      this.$store.commit("root/loading", true);
      this.matchesUpdating = true;
      const matchIDs = Object.keys(this.trackMatches);
      const matches = matchIDs.map(x => {
        const { venueId, timeslot, field } = this.trackMatches[x].changes;
        const fieldV = field && field !== "undefined" ? field : "";
        return {
          _id: parseInt(x),
          venueId,
          fieldNo: fieldV,
          dateTime: timeslot
        };
      });
      const rr = refresh ? false : true;
      this.$http
        .put("nrl/api/v1/admin/matches/grid/bulk-edit", { matches })
        .then(() => {
          this.gridChanges = false;
          const { start, end } = this.grid.date;
          this.getMatches([start, end], rr);
          if (!rr) {
            setTimeout(() => {
              this.$store.commit("root/loading", false);
              this.matchesUpdating = false;
            }, 250);
          }
        })
        .catch(err => {
          this.$customError(_.get(err, "response.data.message"));
          this.$store.commit("root/loading", false);
          this.matchesUpdating = false;
        });
    },
    orderGroup() {
      this.grid.groupsLvl1 = _.sortBy(this.grid.groupsLvl1, x => x.content);
      // move N/A to head of the group
      const naElement = _.find(this.grid.groupsLvl1, { id: "undefined" });
      if (naElement) {
        this.grid.groupsLvl1 = _.remove(this.grid.groupsLvl1, x => x.id !== "undefined");
        this.grid.groupsLvl1.push(naElement);
      }
    },
    populateGrid(newVenue = false) {
      const venues = document.querySelectorAll("[data-venue]");
      venues.forEach(v => {
        const clashedMatches = v.querySelectorAll(".clash");
        clashedMatches.forEach(c => {
          if (!c.parentNode.classList.contains("has-clash"))
            c.parentNode.classList.add("has-clash");
        });
        const clashTimeslots = v.querySelectorAll(".has-clash");
        for (const t of clashTimeslots) {
          const alertExists = t.querySelector(".clash-alert");
          if (!alertExists) {
            const alert = document.createElement("div");
            alert.classList.add("clash-alert");
            alert.innerHTML = `<div><svg xmlns="http://www.w3.org/2000/svg" style="width: 12px; height: 12px" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
          </svg><span>Clashing Matches</span></div>`;
            t.prepend(alert);
          }
        }
      });
      this.equaliseHeight();
      if (!newVenue) this.setEventlisteners();
      this.$store.commit("root/loading", false);
    },
    updateIncrement(v) {
      this.timeslotIncrements = v;
    },
    updatePanelHeight(v) {
      this.recentPanelHeight = v;
    },
    async updateMatch(valid) {
      if (await valid) {
        try {
          //  Update match
          this.$store.commit("root/loading", true);
          this.matchesUpdating = true;
          const url = `/nrl/api/v1/admin/matches/${this.editMatch._id}`;
          const update = this.$store.getters["match/matchForSubmit"];
          if (this.overrideScores) {
            update.scores.homeTeam = this.$store.getters["match/homeScore"];
            update.scores.awayTeam = this.$store.getters["match/awayScore"];
          }
          this.$http.put(url, this.$store.getters["match/matchForSubmit"]);
          this.cancelForm();
          const { start, end } = this.grid.date;
          this.getMatches([start, end]);
          console.log("updating match");
          this.$store.commit("root/loading", false);
          this.$customSuccess();
        } catch (error) {
          this.$store.commit("root/loading", false);
          this.matchesUpdating = false;
          this.$customError(error);
        }
      }
      return true;
    },
    updateTimeRange(first, v) {
      const timeArr = v.split(":");
      const pos = first ? "start" : "finish";
      this.timesBetween[pos] = parseInt(timeArr[0]);
    },
    timesBetweenWatcher() {
      this.$store.commit("root/loading", true);
      this.gridRefresh = !this.gridRefresh;
      const { competitions, groupsLvl1, items } = this.grid;
      this.formatGrid(competitions, groupsLvl1, items);
      this.gridRefresh = !this.gridRefresh;
      setTimeout(() => {
        this.populateGrid();
        this.$store.commit("root/loading", false);
      }, 500);
      return "I'm running";
    }
  },
  computed: {
    gridDate() {
      const vm = this;
      const { day } = vm.grid.date;
      vm.setDay = this.grid.date.day = moment(new Date(day));
      this.grid.date.start = vm.setDay.startOf("Day").valueOf();
      this.grid.date.end = vm.setDay.endOf("Day").valueOf();
      return vm.setDay;
    },
    gridSize() {
      if (this.noCols === 1) return "sm";
      if (this.noCols === 2) return "med";
      if (this.noCols > 2) return "lge";
      return "init";
    },
    noCols() {
      let gridNoCols = 0;
      this.grid.groupsLvl1.forEach(v => {
        gridNoCols += v.venueFields.length;
      });
      return gridNoCols;
    }
  },
  watch: {
    matches(matches) {
      this.$store.commit("matchGrid/updateMatches", matches);
    },
    setDay() {
      const { start, end } = this.grid.date;
      this.getMatches([start, end]);
    },
    timeslotIncrements() {
      this.timesBetweenWatcher();
    },
    "timesBetween.start": function(v) {
      this.timesBetweenWatcher();
    },
    "timesBetween.finish": function() {
      this.timesBetweenWatcher();
    }
  }
};
</script>
